.main_nav {
    margin: 1em 0.5em;
}

.main_nav a {
    display: inline-block;
    padding: 1em 0.5em;
    font-size: 1.2em;
}

.active {
    font-weight: bold;
}