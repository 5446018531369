.header {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.header_image {
    display: inline-block;
    max-width: 128px;
    vertical-align: middle;
    margin-right: 0.5em;
}

.header_banner_link {
    text-decoration: none;
    color: rgb(0,30,0);
}

.header_banner_link:hover {
    text-decoration: none;
    color: rgb(0,0,0);
}

@media (max-width: 600px) {
    .header {
        text-align: center;
    }

    .header_image {
        display: block;
        margin: auto;
    }
}