body {
  font-family: 'Ink Free', 'Bradley Hand', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14pt;

  padding: 0.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  h1, h2, h3, h4, h5, p, div {
    text-align: center;
  }
  
  ul {
    list-style: none;
    margin: 1em 0em;
    padding: 0;
  }

  li {
    display: block;
  }

  li::after {
    display: block;
    content: "-";
  }

  li:last-child::after {
    display: none;
  }
}